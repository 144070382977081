<template>
  <div class="the-home">
    <div class="container">
      <b-row>
        <b-col class="text-center" cols="12">
          <h1>แบบทดสอบ</h1>
          <h2 :class="maincolor">คุณขอเลิกกิจการได้แล้วหรือยัง</h2>
          <img class="img img-desktop img-mobile" src="../assets/head-line.png" alt="Store Images"/>
        </b-col>
      </b-row>
      <b-row>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
         <b-col>
    <TheAssessmentFormCard :questions="questions" v-model="questions" />
  </b-col>
          <b-col class="text-center padding" cols="12">
            <b-button
              variant="warning"
              @click="handleSubmit(submit)"
              type="submit"
              :class="{ 'btn-disabled': !valid }"
            >
              <img
                class="img"
                src="../assets/icon-view.png"
                alt="Store Images"
                height="25px"
                width="30px"
              />
              ประมวลผล </b-button
            >&nbsp;&nbsp;&nbsp;
            <b-button variant="secondary" @click="refreshComponent"
              >ล้างข้อมูล</b-button
            >
          </b-col>
        </ValidationObserver>
      </b-row>
      <b-row> </b-row>
    </div>
  </div>
</template>
<script>
import TheAssessmentFormCard from "./TheAssessmentFormCard.vue";
// import TheUnderLineImage from "./TheUnderLineImage.vue";
// import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: "TheHome",
  components: {
    TheAssessmentFormCard,
    // TheUnderLineImage,
  },
  data() {
    return {
      questions: [
        {
          title:
            "1.ผู้ถือหุ้นมีความประสงค์เลิกกิจการ โดยผู้ถือหุ้นได้มีการประชุม 2 ครั้ง เพื่อลงมติพิเศษให้เลิกบริษัทแล้ว",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title: "2.ผู้ถือหุ้นได้มีการลงมติว่าต้องการยกเลิกบริษัท โดยมีคะแนนเสียงไม่น้อยกว่า 3 ใน 4 ของจำนวนหุ้นที่เข้าประชุมแล้ว",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "3.กรณีกิจการมีเจ้าหนี้หรือลูกหนี้ สามารถตกลงเรื่องการชำระหนี้ได้เรียบร้อย พร้อมยินยอมให้เลิกกิจการแล้ว",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "4.ได้จัดตั้งบริษัทโดยกำหนดเวลาสิ้นสุดไว้ และสิ้นสุดเวลาที่กำหนดแล้ว",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "5.เลิกกิจการโดยคำสั่งศาล เช่น บริษัทไม่เริ่มประกอบการภายใน 1 ปี นับแต่จดทะเบียนบริษัท หรือหยุดทำการถึง 1 ปี",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
          "6.บริษัทล้มละลาย",
          isTrue: null,
          ishighRisk: true,
        },
      ],
      maincolor: "maincolor",
      warning: "warning",
    };
  },
  methods: {
    submit() {
  const hasYesAnswer123 = this.questions
    .slice(0, 3)
    .every((question) => question.isTrue === true);
  const allQuestionsAnswered = this.questions.every(
    (question) => question.isTrue !== null
  );

  if (!allQuestionsAnswered) {
    alert("กรุณาตอบคำถามทั้งหมด");
    return;
  }

  if (hasYesAnswer123) {
    this.$router.push("/add-branch").catch(() => {});
  } else if (
    this.questions.slice(0, 3).some((question) => question.isTrue === false) &&
    this.questions.slice(3, 6).some((question) => question.isTrue === true)
  ) {
    this.$router.push("/add-branch").catch(() => {});
  } else {
    this.$router.push("/no-branch").catch(() => {});
  }
},

    refreshComponent() {
      this.questions.forEach((question) => {
        question.isTrue = null;
      });
    },
    isDisabled() {
      return false;
    },
    calculateRisk() {
      this.questions.some((question) => {
        console.log(question.title);
        if (question.isTrue && question.ishighRisk) {
          this.$router.push("/AddBranch").catch(() => {});
          return true;
        } else {
          this.$router.push("/NoBranch").catch(() => {});
          return false;
        }
      });
    },


  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-disabled {
  background-color: gray;
  color: white;
}

.maincolor {
  color: #ea6b0d;
}
.warning {
  color: #ea6b0d;
}

.padding {
  padding-bottom: 20px;
  padding-top: 20px;
}
.btn-warning {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}

.btn-warning:hover {
  color: #ea6b0d;
  background-color: #ffffff;
  border-color: #ea6b0d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
  box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
}
.btn-secondary:hover {
  color: #5a6268;
  background-color: #ffffff;
  border-color: #545b62;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ea6b0d !important;
  background-color: #ea6b0d !important;
}
.img-desktop{
  padding-bottom: 30px; 
  width: 60%;
}
@media (max-width: 768px) {
  .img-mobile{
  padding-bottom: 30px; 
  width: 100%;
}

}

</style>

<template>
  <div class="the-home">
    <div class="container">
      <b-row>
        <b-col class="text-center" cols="12">
          <h1>ผลประเมินของคุณ!!</h1>
          <img class="img img-desktop img-mobile" src="../assets/head-line.png" alt="Store Images"/>
      </b-col>
      </b-row>

      <b-card
        style="
          box-shadow: 1px 1px 12px 5px darkgrey;
          border: 0px solid rgba(0, 0, 0, 0.125);
        ">
        <img
          class="img"
          src="../assets/risk.png"
          alt="Store Images"
        />&nbsp;&nbsp;&nbsp;
        <b-button variant="warning">คุณสามารถดำเนินการขอเลิกกิจการได้</b-button>
        <!-- <b-button>คุณอยู่ในระดับ “ความเสี่ยงสูง”</b-button> -->
        <br />
        <br />
        <h2>คำอธิบาย</h2>
        <h5>
          จากแบบทดสอบสามารถสรุปได้ว่า คุณสามารถดำเนินการขอจดทะเบียนเลิกกิจการได้ ซึ่งผู้ประกอบการจะต้องดำเนินการขอยกเลิกกับหน่วยงานที่เกี่ยวข้อง ดังนี้
<li>กรมพัฒนาธุรกิจการค้า กระทรวงพาณิชย์ ได้แก่ 1) จดทะเบียนเลิกบริษัท 2) จัดทำงบการเงิน ณ วันเลิก และส่งให้ผู้ตรวจสอบบัญชีรับรอง และ 3) จดทะเบียนเสร็จชำระบัญชี</li>
<li>กรมสรรพากร ได้แก่ 1) แจ้งปิดบริษัทภายใน 15 วัน 2) ยื่น ภ.ง.ด.50 ของงบที่เลิกบริษัท และ 3) คืนใบ ภ.พ.20 ตัวจริง</li>
<li>สำนักงานประกันสังคม ได้แก่ แจ้งเลิกกิจการ</li>
ทั้งนี้ ก่อนเลิกกิจการผู้ประกอบการจะต้องเคลียร์งบการเงินและภาษีให้เรียบร้อยก่อน ซึ่งจำเป็นต้องจ้างสำนักงานบัญชี เพื่อความปลอดภัยในการวิเคราะห์การเลิกจ้าง เนื่องจากหากวิเคราะห์ไม่ดีไม่รัดกุม มีโอกาสถูกเรียกเก็บภาษีค่อนข้างมาก
ดังนั้น สามารถใช้บริการสำนักงานบัญชี <a
            :class="maincolor"
            href="https://inflowaccount.co.th/"
            target="_blank"
            rel="noopener noreferrer"
            >@inflowaccount</a
          > หรือขอคำแนะนำเพิ่มเติมได้ที่          <a
            :class="maincolor"
            href="https://page.line.me/361ykfds?openQrModal=true"
            target="_blank"
            rel="noopener noreferrer"
            >@inflowaccount</a
          >           หรือ
          <a :class="maincolor" href="tel:0934142885">โทร.093-4142885</a> เพราะทุกปัญหาเรื่องภาษีใกล้ตัวคุณมากกว่าที่คุณรู้
          <br />
        </h5>
      </b-card>
      <b-row>
        <b-col class="text-center padding" cols="12">
          <router-link to="/">
            <b-button variant="warning">กลับ</b-button>&nbsp;&nbsp;&nbsp;
          </router-link>
          <router-link to="/">
            <b-button variant="warning">ทำแบบทดสอบอีกครั้ง</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </div>
  </div>
</template>
<script>
// import n from "./TheUnderLineImage.vue";
export default {
  components: {
    // TheUnderLineImage,
  },
  data() {
    return {
      maincolor: "maincolor",
      warning: "warning",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-desktop{
  padding-bottom: 30px; 
  width: 60%;
}
@media (max-width: 768px) {
  .img-mobile{
  padding-bottom: 30px; 
  width: 100%;
}
}
.maincolor{
color:#EA6B0D;
}
.warning {
  color: #ea6b0d;
}

.padding {
  padding-bottom: 20px;
  padding-top: 20px;
}
.btn-warning {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}

.btn-warning:hover {
  color: #ea6b0d;
  background-color: #ffffff;
  border-color: #ea6b0d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
  box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
}
.btn-secondary:hover {
  color: #5a6268;
  background-color: #ffffff;
  border-color: #545b62;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
</style>

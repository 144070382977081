
<template>
  <div id="app" class="mt-5">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    document.title = "Inflow Account - Company Branch Assessment";
  },
};
</script>

<style></style>
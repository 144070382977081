<template>
  <div class="NoBranch">
    <NoBranch />
  </div>
</template>
<script>
// @ is an alias to /src
import NoBranch from "@/components/NoBranch.vue";

export default {
  name: "NoBranchView",
  components: {
    NoBranch,
  },
};
</script>

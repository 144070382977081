import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NoBranchView from "../views/NoBranchView.vue";
import AddBranchView from "../views/AddBranchView";
import TheAssessmentFormCard from "../components/TheAssessmentFormCard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/add-branch",
    component: AddBranchView,
  },
  {
    path: "/no-branch",
    component: NoBranchView,
  },
  {
    path: "/assessment",
    name: "assessment",
    component: TheAssessmentFormCard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="AddBranch">
    <AddBranch />
  </div>
</template>
<script>
// @ is an alias to /src
import AddBranch from "@/components/AddBranch.vue";

export default {
  name: "AddBranchView",
  components: {
    AddBranch,
  },
};
</script>
